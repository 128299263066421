

































































































































































::v-deep .dialog-message {
  .el-dialog__body {
    padding-bottom: 50px;
  }
}

.message {
  .message__title {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }

  .message__info {
    line-height: 1.4em;
    font-size: 14px;
    color: #666;

    .message__info__title {
      margin-right: 4px;
      color: #999;
    }

  }

  .message__content {
    margin-top: 16px;
    line-height: 1.5em;
    color: #333;
  }

  &.message--hasReply {
    margin: 16px 0 0;
    padding: 20px 20px 16px;
    border-radius: 4px;

    background: rgba(234, 234, 234, .5);

    .message__title {
      color: #999;
    }
  }

  &.message-management {
    .message__title {
      color: #0099a1;
      font-size: 16px;

      .icon-v {
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 14px;
        margin: 0 0 0 4px;
        background: url('~@/assets/images/icon-v.png');
      }
    }
  }
}

.messageReply {
  margin: 36px 0 0;

  .messageReply__textarea {
    height: 120px !important;
    padding: 2px;
    background: #FFFFFF;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    background: none !important;
    box-sizing:border-box!important;

    ::v-deep .el-textarea__inner {
      padding: 6px 8px;
      background: none !important;
      resize: none;
    }

    ::v-deep .el-input__count {
      display: block;
      margin-bottom: 2px;
      line-height: 1em;
    }
  }

  .z-btn {
    display: block;
    margin: 42px auto 0;
  }
}

