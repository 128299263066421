



















.panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  /*height: 318px;*/
  height: 100%;
  /*margin-right: 28px;*/
  box-shadow: 0px 6px 20px 0px rgba(235, 235, 235, 0.86);
  border-radius: 10px;
  background: #fff;

  .panel__hd {
    flex: none;
    height: 46px;
    padding: 6px 8px;
    box-sizing: border-box;
  }

  .panel__bd {
    flex: 1;
    /*margin: 0 4px 38px;*/
    margin: 0 4px 6%;
    padding: 0 5px;
    overflow: auto;
  }
}
