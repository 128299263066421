

































































.panel--notice {
  flex: 1;
}

.messageItem {
  position: relative;
  display: flex;
  align-items: end;
  padding: 16px 0 18px;
  border-bottom: 1px solid #eaeaea;
  overflow: hidden;

  &::before {
    position: absolute;
    /*left: 19px;*/
    left: 3.2%;
    top: 21px;
    content: '';
    width: 10px;
    height: 10px;
    border: 2px solid #EAEAEA;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .messageItem__colLeft {
    position: relative;
    flex: 1;
    /*padding-left: 36px;*/
    padding-left: 6.1%;

    .messageItem__colLeft__title {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.4em;
      color: #666;
      font-size: 16px;
      font-weight: bold;
    }

    .messageItem__colLeft__date {
      margin: 6px 0 0;
      color: rgba(51, 51, 51, .7);
      font-size: 12px;
    }
  }

  .messageItem__colRight {
    flex: none;
    width: 25%;
    min-width: 120px;
    text-align: center;
  }
}
.empty-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #ccc;
  font-size: 16px;
}

