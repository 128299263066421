


































































.el-scrollbar__view {
  height: auto;
}

.homePage {
  display: flex;
  height: calc(100vh - 63px);
  padding-top: 42px !important;
  /*margin: 42px 30px 20px 30px;*/

  .homePage__colLeft {
    flex: none;
    /*display: flex;
    align-items: center;
    flex-direction: column;*/
    max-width: 385px;
    width: 25%;
    min-width: 230px;
    /*min-width: 330px;*/
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 32px 0px rgba(222, 222, 222, 0.86);

    /*min-height: 600px;*/

    .city_country_panel {
      margin-top: 20px;
      width: 100%;

      .address_panel,
      .date_panel {
        text-align: left;

        img {
          //margin-right: 5px;
          transform: translate(1px, 2px);
          margin-right: 6px;
        }
      }

      .date_panel {
        margin-left: 46px;
      }

      .address_panel {
        padding-left: 40px;
      }
    }

    .person_mess_panel {
      width: 100%;
      margin-top: 20px;
      padding: 0 30px 10px 30px;
      border-bottom: 1px solid #eeeeee;

      div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .person_position,
    .person_com {
      font-size: 15.5px;
      line-height: 37px;
      font-weight: bold;
      color: #666;
    }

    .gray {
      color: #999999;
    }

  }

  .homePage__colRight {
    position: flex;
    flex-direction: column;
    display: flex;
    width: 100%;
    // margin-left: 30px;
    margin-left: 1.5625vw;

    .homePage__colRight__chart {
      flex: 1;
    }

    .homePage__colRight__messagePanel {
      flex: none;
      display: flex;
      justify-content: space-between;
      margin: 1.46vw 0 .83vw;
      height: 40%;
      /*max-height: 318px;*/
    }
  }

}

