














































































































































































































.panel--chart {
  flex: 1;

  ::v-deep .panel__bd {
    margin: 0;
    padding: 0 20px 26px;
    overflow: visible;
  }
}

.messageItem {
  display: flex;
  align-items: center;
  padding: 16px 0 18px;
  border-bottom: 1px solid #eaeaea;

  .messageItem__colLeft {
    position: relative;
    flex: 1;
    padding-left: 36px;

    &::before {
      position: absolute;
      left: 19px;
      top: 7px;
      content: '';
      width: 10px;
      height: 10px;
      border: 2px solid #EAEAEA;
      box-sizing: border-box;
      border-radius: 5px;
    }

    .messageItem__colLeft__title {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.4em;
      color: #666;
      font-size: 16px;
      font-weight: bold;
    }

    .messageItem__colLeft__date {
      margin: 6px 0 0;
      color: rgba(51, 51, 51, .7);
      font-size: 12px;
    }
  }

  .messageItem__colRight {
    flex: none;
    padding: 0 28px;
  }
}

