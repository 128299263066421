















































































































































































































.HomeUser {
  text-align: center;
}

.brandInfo {
  padding-bottom: 26px;
  border-bottom: 1px solid #eaeaea;
}

.location {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 2%;
  justify-content: space-around;

  .item {
    flex: none;
    margin: 8px 0;
    color: #666;
    font-size: 14px;

    .icon {
      margin-right: 6px;
      vertical-align: -2px;
    }

    .text {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.entrance {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15% 0 0;
  padding: 0 10.6%;

  .entrance__item {
    position: relative;
    width: 50%;
    /*margin: 5% 13.8%;*/
    margin: 5% 0%;
    cursor: pointer;

    .entrance__item__icon {
      border-radius: 30px;
    }

    .entrance__item__num {
      position: absolute;
      left: 50%;
      margin-left: 12px;
      top: -3px;
      min-width: 22px;
      height: 19px;
      padding: 0 4px;
      box-sizing: border-box;
      line-height: 19px;
      font-size: 12px;
      font-weight: bold;
      color: #E48B00;
      background: linear-gradient(-40deg, #ffd000 0%, #ffdc41 100%);
      border-radius: 10px;
    }

    .entrance__item__title {
      margin: 8px 0 0;
      color: #666;
      font-size: 14px;
    }

    &.active,
    &:hover {
      .entrance__item__icon {
        box-shadow: 0px 2px 10px 0px rgba(40, 204, 204, 0.56);
      }
    }
  }
}

.avatarWrap {
  margin: -42px auto 0;
  padding: 8px;
  display: flex;
  /*width: 147px;*/
  /*height: 147px;*/
  width: 38%;
  aspect-ratio: 1/1;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  background: #fff;
  overflow: hidden;

  .avatar {
    max-width: 100%;
  }
}

.brandName {
  margin-top: 20px;
  font-size: 30px;
  font-weight: bold;
  color: #666666;
  line-height: 1em;
}

.fansNum {
  margin: 10px 0 0;
  font-size: 16px;
  line-height: 1em;
  color: #999999;
}

